import * as React from 'react';
import classNames from 'classnames';
import {
  IBaseSelectProps,
  IComboBoxInputImperativeActions,
  IComboBoxInputProps,
} from '../ComboBoxInput.types';
import { HAS_CUSTOM_FOCUS_CLASSNAME } from '../../../core/commons/a11y';
import CustomSelect from './CustomSelect/CustomSelect';
import NativeSelect from './NativeSelect/NativeSelect';

const noop = () => {};
const skinWithoutLabel = 'ComboBoxInputVerticalMenuSkin';

type IComboBoxInputBaseRenderProp = Pick<
  IComboBoxInputProps,
  'id' | 'onClick' | 'onDblClick' | 'onMouseEnter' | 'onMouseLeave'
> & {
  content: React.ReactNode;
  className: string;
  ariaLabel?: string;
};

type IComboBoxInputBaseProps = IComboBoxInputProps & {
  styles: { [key: string]: string };
  children(props: IComboBoxInputBaseRenderProp): React.ReactElement;
};

const ComboBoxInputBase: React.ForwardRefRenderFunction<
  IComboBoxInputImperativeActions,
  IComboBoxInputBaseProps
> = (props, ref) => {
  const {
    id,
    skin,
    label,
    styles,
    value,
    children,
    required,
    isDisabled,
    placeholder,
    shouldShowValidityIndication,
    validateValueAndShowIndication = noop,
    onBlur = noop,
    onFocus = noop,
    onChange = noop,
    onSelectedOptionChange = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    designableList = false,
    options,
    setDesignableListElem,
    forceOpenDesignableList = false,
  } = props;

  const inputRef = React.useRef<HTMLSelectElement>(null);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          inputRef.current?.setCustomValidity(message.message);
        }
      },
    };
  });

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const hideLabel = skinWithoutLabel === skin;
  const placeholderValue = placeholder && placeholder.value;
  const isLegacyPlaceholderSelected = value === placeholderValue;
  const isPlaceholderSelected =
    isLegacyPlaceholderSelected || (placeholderValue && value === '');

  const rootClassName = classNames(styles[skin], styles.root, {
    [styles.hasLabel]: !!label,
    [styles.withRequiredIndication]: required,
    [styles.withValidationIndication]: shouldShowValidityIndication,
  });

  const selectClassName = classNames(
    styles.select,
    HAS_CUSTOM_FOCUS_CLASSNAME,
    {
      [styles.extendedPlaceholderStyle]: isPlaceholderSelected,
    },
  );

  const _handleOnSelectedOptionChange = (optionValue: string) => {
    onSelectedOptionChange(optionValue);
    validateValueAndShowIndication();

    // onChange expects to get a full React event, but we rely on viewer platform the add target & context.
    onChange({ type: 'change', compId: id } as any);
  };

  const selectProps: IBaseSelectProps = {
    ref: inputRef,
    className: selectClassName,
    styles,
    id,
    onFocus,
    onSelectedOptionChange: _handleOnSelectedOptionChange,
    onBlur,
    disabled: isDisabled,
    required,
    value,
    options,
    placeholder,
  };

  const content = (
    <>
      {hideLabel ? null : (
        <label className={styles.label} htmlFor={`collection_${id}`}>
          {label}
        </label>
      )}
      {designableList ? (
        <CustomSelect
          {...selectProps}
          setDesignableListElem={setDesignableListElem}
          forceOpenDesignableList={forceOpenDesignableList}
        />
      ) : (
        <NativeSelect {...selectProps} />
      )}
    </>
  );

  return children({
    id,
    className: rootClassName,
    onClick,
    onDblClick,
    onMouseEnter: _onMouseEnter,
    onMouseLeave: _onMouseLeave,
    content,
    ariaLabel: label,
  });
};

export default React.forwardRef(ComboBoxInputBase);
